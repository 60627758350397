<template>
  <div class="bg">
    <div class="wrapper">
      <div class="floor floor-t">
        <div class="floor-t-l">
          <img style="width: 500px; height: 280px;" :src="`/_fid/` + courseInfo.cover" alt="">
        </div>
        <div class="floor-t-r">
          <div class="floor-t-r-title">{{courseInfo.name}}</div>
          <div class="floor-t-r-hole">
            <div style="font-size: 16px;">课件: {{courseList.length}} 个</div>
            <div class="spa">{{Math.ceil(num/3600)}}学时</div>
          </div>
          <div class="floor-t-r-letter line-text-3">{{courseInfo.introduction}}</div>
          <div class="floor-t-r-label">
            <div class="label-i" v-for="(item, index) in labelArr" :key="index">{{item}}</div>
          </div>
          <div class="floor-t-r-position">
            <div>
              <icon-user style="color: #7B7B7B; margin-right: 8px; font-size: 18px;" />
              <span>{{courseInfo.joinStudy}}</span>
              <!-- <icon-star-fill style="color: #7B7B7B; margin-left: 20px; margin-right: 8px; font-size: 18px;" />
              <span>{{courseInfo.collects}}</span> -->
            </div>
            <!-- <div class="btn" @click="addStudy">
              {{
                courseInfo.signState == '0' ? '报名学习'
                    :courseInfo.signState == '1' ? '等待审核'
                      :courseInfo.signState == '2' ? '无需审核'
                        :courseInfo.signState == '3' ? '审核通过'
                            :courseInfo.signState == '4' ? '审核拒绝':'报名学习'
              }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="floor floor-b">
        <div class="tab">
          <div class="tab-pane" @click="handleTrigger(index)" :class="index == active ? 'active': ''" v-for="(item, index) in tab" :key="index">{{item}}</div>
        </div>
        <div class="floor-b-a" v-if="active == 0" v-html="courseInfo.intro">
        </div>
        <div class="floor-b-b" v-if="active == 1">
          <div class="btnBox">
            <a-button-group v-if="btnList.length > 1" type="primary" >
              <a-button :class="{'all': textValueIndex == -1}" @click="wareSearch('all','all', -1)">全部</a-button>
              <a-button
                v-for="(item,index) in btnList" :key="index"
                type="outline"
                :class="{'all': textValueIndex == index}"
                @click="wareSearch('dan', item.id, index)"
              >{{item.name}}</a-button>
            </a-button-group>
            <!-- <div class="text" v-if="textValue == 'dan'">已加入学分 : <span>{{joinGroupCredit}}</span> / {{totalScore}}</div> -->
          </div>
            <a-table :bordered="false"  :data="courseList">
              <template #columns>
                <a-table-column title="序号" data-index="index" :width="60" />
                <a-table-column title="课件名称" data-index="name">
                  <template #cell="{record}">
                    <span class="wareSpan" @click="waredetail(record)">{{record.name}}</span>
                  </template>
                </a-table-column>
                <a-table-column v-if="btnList.length > 1" title="分组名称" data-index="groupName"/>
                <!-- <a-table-column title="学分" data-index="credit"/> -->
                <a-table-column title="时长" data-index="durationsNew"/>
                <!-- <a-table-column title="状态" data-index="intro" v-if="token != null && (courseInfo.signState == 3 || courseInfo.signState == 2)"> 
                  <template #cell="{record}">
                    {{record.state == '1'?'已加入':'未加入'}}
                  </template>
                </a-table-column>
                <a-table-column title="操作" data-index="intro" v-if="token != null && (courseInfo.signState == 3 || courseInfo.signState == 2)">
                  <template #cell="{record}">
                    <a-tag color="purple" bordered style="cursor: pointer;" @click="joinWare(record)">{{record.state == '1'?'继续学习':'加入学习'}}</a-tag>
                  </template>
                </a-table-column> -->
              </template>  
            </a-table>
        </div>
        <div class="floor-b-c" v-if="active == 2">
          <!-- :pagination="pagination" @page-change="change" -->
          <a-table :bordered="false" :data="courseInfo.teachers">
            <template #columns>
              <!-- <a-table-column title="头像" data-index="avatar">
                  <template #cell="{ record }">
                    <img style="width: 50px; height: 50px; border-radius: 50%" :src="record.avatar" alt="" srcset="">
                  </template>
              </a-table-column> -->
              <a-table-column title="姓名" data-index="name" width="130"/>
              <a-table-column title="简介" data-index="intro" width="500" :ellipsis="true">
                  <template #cell="{record}">
                    <a-tooltip :content="record.intro">
                      <div class="line-text-2" style="">{{record.intro}}</div>
                    </a-tooltip>
                  </template>
              </a-table-column>
              <a-table-column title="单位" data-index="company"/>
              <a-table-column title="职称" data-index="title" />
            </template>  
          </a-table>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="visible" title=" " @ok="handleOk" @cancel="handleCancel">
      <div>请先完成登录！</div>
    </a-modal>
    <a-modal v-model:visible="joinvisible" title=" " @ok="handleOk1" @cancel="handleCancel1">
      <div>确定加入该课件？</div>
    </a-modal>
  </div>
  
</template>

<script setup>
  import { reactive, ref, toRefs } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import * as courseApi from '@/api/course';
  import { Message} from '@arco-design/web-vue';
  import { formatSeconds } from '@/utils/util';
import { async } from 'q';

  
  const route = useRoute();
  const detailId = route.query.id;

  var token = localStorage.getItem('webtoken')
  const tab = ref(['课程介绍', '课程内容', '课程讲师']);
  const active = ref(0);
  const getPagination = () => {
    return {
      pageItemStyle:{
        border: '1px solid rgba(0,0,0,0.1500)',
        borderRadius: '2px' 
      },
      activePageItemStyle:{ 
        backgroundColor: 'transparent',
        border: '1px solid #207AFF', color: '#207AFF'
      },
      defaultCurrent: 1,
      pageSize: 10,
      total: 5
    }
  };
  const pagination = ref(getPagination())
  //详情
  const courseInfo = ref({});
  const courseList = ref([]);//课件列表
  const labelArr = ref([]);//标签展示
  const getCourseInfo = () => {
     courseApi.courseInfo({id:detailId}).then(res => {
       if(res.result != null){
        const data = res.result;
        const tList = data.teachers;
        let obj = {};
        let peon = tList.reduce((cur,next) => {
          obj[next.id] ? '' : obj[next.id] = true && cur.push(next);
          return cur;
        },[]);
        data.teachers = peon;
         courseInfo.value = data;
         courseInfo.value.signState = 2;
         labelArr.value = Array.from(new Set(courseInfo.value.joinWareLabels.split('、')));
       }
     })
  };
  getCourseInfo();
  //加入学习
  const visible = ref(false);
  const addStudy = () => {
    if(!token){
      visible.value = true
    }else{
      courseApi.courseSign({id:detailId}).then(res => {
        if(res.code == 20000){
          getCourseInfo();
        }
      })
    }
  };
  const handleOk = () => {
    visible.value = false;
    router.push({ path: '/login',query:{id: detailId}})
  };
  const handleCancel = () => {
    visible.value = false;
  };
  const handleTrigger = (index) => {
    active.value = index
  }
  //课件组列表
  const btnList = ref([]);
  const getGroupList = () => {
     courseApi.groupList({id:detailId}).then(res => {
       btnList.value = res.result;
     })
  };
  //分组数据
  const num = ref(0)
  const getList = () => {
    courseApi.wareInfo({courseId:detailId,wareGroupId:''}).then(res => {
      courseList.value = res.result;
      num.value = 0;
      courseList.value.forEach((item,index) => {
        item.index = index + 1;
        num.value += item.durations;
        item.durationsNew = formatSeconds(item.durations);
      })
    })
    
  };
  
  getList();
  //分组查询
  const textValue = ref('all');
  const textValueIndex = ref(-1);

  const joinGroupCredit = ref(0);
  const totalScore = ref(0);
  const wareSearch = (val,id,index) => {
    textValueIndex.value = index;
    textValue.value = val;
    if(val == 'all'){
        courseApi.wareInfo({courseId:detailId,wareGroupId:''}).then(res => {
          courseList.value = res.result;
          courseList.value.forEach((item,index) => {
            item.index = index + 1;
            item.durationsNew = formatSeconds(item.durations);
          })
          btnList.value.forEach(item => {
            if(item.id == id){
              joinGroupCredit.value =  item.joinGroupCredit == null ? 0 : item.joinGroupCredit;
              totalScore.value =  item.totalScore == null ? 0 : item.totalScore;
            }
          })

        })
    }else{
      courseApi.wareInfo({courseId:detailId,wareGroupId:id}).then(res => {
        courseList.value = res.result;
        courseList.value.forEach((item,index) => {
          item.index = index + 1;
          item.durationsNew = formatSeconds(item.durations);
        })
        btnList.value.forEach(item => {
          if(item.id == id){
            joinGroupCredit.value =  item.joinGroupCredit == null ? 0 : item.joinGroupCredit;
            totalScore.value =  item.totalScore == null ? 0 : item.totalScore;
          }
        })
      })
    }
    
  };
  getGroupList();
  //跳转课件
  const router = useRouter()
  const waredetail = async (e) => {
    const data = await courseApi.wareCheckPlay({ id: detailId });
    if (data.code === 20000) {
      router.push({
        path: '/course/courseware_b',
        query:{
          courseId: detailId,
          id: e.id,//课件id
          classId: data.result.classId,
        }
      });
    }

    // if(courseInfo.value.classId == null){
    //   Message.warning('请先联系管理员报名！');
    // }else{
    //   router.push({
    //     path: '/course/courseware_b',
    //     query:{
    //       courseId:detailId,
    //       id:e.id,//课件id
    //       classId:courseInfo.value.classId
    //     }
    //   })
    // }
    // else{
    //   router.push({
    //     path: '/course/courseware_a',
    //     query:{
    //       courseId:detailId,
    //       id:e.id,//课件id
    //       state:e.state,//课件状态
    //       signState:courseInfo.value.signState//课程报名状态
    //     }
    //   })
    // }
    
  };
  //加入课件
  const joinvisible = ref(false);
  const groupId = ref('');
  const wareId = ref('');
  const joinWare = (e) => {
    groupId.value = e.groupId;
    wareId.value = e.id;
    if(e.state == 1){
      router.push({
        path: '/course/courseware_b',
        query:{
          courseId:detailId,
          id:e.id,
        }
      })
    }else{
       joinvisible.value = true;
    }
  };
  const handleOk1 = () => {
    let params = {
      courseId:detailId,
      groupId:groupId.value,
      wareId:wareId.value
    }
    courseApi.wareJoin(params).then(res => {
      if(res.code == 20000){
        joinvisible.value = false;
        wareSearch();
      }
    })
  };
  const handleCancel1 = () => {
       joinvisible.value = false;
  };
  const change = (val) => {
    console.log('第几页' + val)
  }
</script>

<style lang="less" scoped>
.bg {
  background: #F7F6FA;
  padding: 20px 0;
  .floor + .floor {
    margin-top: 20px;
  }
  .floor {
    background: #FFFFFF;
    border-radius: 4px;
  }
  .floor-t {
    padding: 20px;
    display: flex;
    .floor-t-l {
      margin-right: 20px;
    }
    .floor-t-r {
      flex: 1;
      position: relative;
      .floor-t-r-title {
        padding-top: 2px;
        font-size: 20px;
        color: #323233;
        font-weight: 700;
      }
      .floor-t-r-hole {
        margin: 16px 0;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        color: #4F17A8;
        .spa {
          margin-left: 20px;
          padding: 0 12px;
          height: 20px;
          display: flex;
          align-items: center;
          background: rgba(147, 32, 255, 0.1);
          box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.0400);
          border-radius: 24px;
          color: #4F17A8;
        }
      }
      .floor-t-r-letter {
        font-size: 14px;
        color: #7B7B7B;
        line-height: 22px;
      }
      .floor-t-r-label {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        .label-i {
          margin-right: 10px;
          margin-bottom: 10px;

        }
        .label-i {
          height: 22px;
          background: #FFF7E6;
          border-radius: 2px;
          border: 1px solid #FFD591;
          padding: 0 10px;
          color: #D46B08;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .floor-t-r-position {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #7B7B7B;
        font-size: 14px;
        .btn {
          cursor: pointer;
          width: 206px;
          height: 48px;
          background: #4F17A8;
          border-radius: 4px;
          font-size: 18px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .floor-b {
    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #e9e9e9;
      .tab-pane {
        display: flex;
        align-items: center;
        margin: 0 24px;
        height: 60px;
        color: #474747;
        cursor: pointer;
        border-bottom: 3px solid transparent;
      }
      .active {
        color: #4F17A8;
        font-weight: 600;
        border-bottom: 3px solid #4F17A8;
      }
    }
    .floor-b-a {
      padding: 20px 30px 40px;
      min-height: 300px;
    }
    .floor-b-b {
      padding: 0 20px 40px;
      .item {
        padding: 20px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E9E9E9;
        .item-left {
          margin-right: 16px;
          width: 220px;
          height: 118px;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1000);
          border-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }
        .item-c {
          flex: 1;
          .item-floor-a {
            color: #333333;
            font-size: 16px;
            font-weight: 700;
          }
          .item-floor-b {
            margin: 8px 0;
            color: #7B7B7B;
            font-size: 12px;
            display: flex;
            height: 20px;
            align-items: center;
            .item-floor-b-l {
              padding: 0 12px;
              height: 20px;
              display: flex;
              align-items: center;
              background: rgba(79,23,168,0.1000);
              box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.0400);
              border-radius: 24px;
              color: #207AFF;
              margin-right: 20px;
            }
          }
          .item-floor-c {
            color: #323333;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .item-right {
          margin-top: 40px;
          display: flex;
          margin-left: 20px;
          .btn {
            cursor: pointer;
            width: 88px;
            height: 32px;
            background: #207AFF;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
    .floor-b-c {
      padding: 20px 30px;
    }
  }
}
.btnBox{
  margin-top: 28px;
  :deep(.arco-btn){
    border-radius: 4px !important;
    margin-right: 20px;
    margin-bottom: 38px;
    border: 1px solid #7E868E;
    height: 32px;
    background: #FFFFFF;
    border-radius: 6px;
    color: #171A1D;
  }
  .all{
    background: #4F17A8;
    color: #fff;
  }
  .text{
    float: right;
    height: 33px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,0.65);
    line-height: 32px;
    span{
      color: #FF610D;
      font-size: 24px;
    }
  }
}
:deep(.arco-spin){
  width: 100% !important;
}
.wareSpan{
  color: #207AFF;
  display: block;
  cursor: pointer;
}
:deep(.arco-pagination){
  display: none;
}
</style>
